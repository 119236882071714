<template>
  <section class="section">
    <search-form></search-form>

    <h4 class="title has-text-centered is-4">Users</h4>
    <b-table
      :data="users.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingUsers"
      class="mt-4 clickable-table user-table"
      @click="goToUser"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>
      <b-table-column field="featured" label="F" v-slot="props">
        <p>
          <b-icon icon="star" :type="props.row.featured ? 'is-warning' : 'is-light'" size="is-small"></b-icon>
        </p>
      </b-table-column>
      <b-table-column field="patreon" label="P" v-slot="props">
        <p>
          <b-icon icon="money-bill-wave" :type="props.row.patreon ? 'is-danger' : 'is-light'" size="is-small"></b-icon>
        </p>
      </b-table-column>
      <b-table-column field="name" label="Name" v-slot="props">
        <p>
          <strong>{{ props.row.name }}</strong>
          <br />
          {{ props.row.email }}
        </p>
      </b-table-column>

      <b-table-column field="level" label="Level" v-slot="props">
        <p>{{ props.row.level }}</p>
      </b-table-column>

      <b-table-column field="experience" label="Experience" v-slot="props">
        <p>{{ props.row.experience }}</p>
      </b-table-column>

      <b-table-column field="created_at" label="Registered" v-slot="props">
        <p>{{ props.row.created_at | dateStandard }}</p>
      </b-table-column>

      <b-table-column field="last_login" label="Last Login" v-slot="props">
        <p>{{ props.row.last_login | dateStandard }}</p>
      </b-table-column>
    </b-table>

    <b-pagination
      class="mt-4"
      v-model="users.current_page"
      :total="users.total"
      :per-page="users.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SearchForm from '@/components/Users/SearchForm.vue';

export default {
  name: 'home',
  components: { SearchForm },
  computed: {
    ...mapState('user', ['loadingUsers', 'users']),
  },
  mounted() {
    this.loadUsers();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadUsers();
    },
  },
  methods: {
    loadUsers() {
      this.$store.dispatch('user/loadUsers', this.$route.query);
    },
    goToUser(row) {
      this.$router.push({ path: `/users/${row.id}` });
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/users?${queryString}` });
    },
  },
};
</script>

<style>
  .user-table {
    font-size: 12px;
  }
</style>
