<template>
  <div>
    <h4 class="title has-text-centered is-4">Search</h4>

    <form @submit="handleSubmit">
      <b-field grouped>
        <b-field label="User ID" custom-class="is-small">
          <b-input v-model="formData.id" size="is-small"></b-input>
        </b-field>

        <b-field label="Name" custom-class="is-small">
          <b-input v-model="formData.name" size="is-small"></b-input>
        </b-field>

        <b-field label="Email" custom-class="is-small">
          <b-input v-model="formData.email" size="is-small"></b-input>
        </b-field>

        <b-field label="Location" custom-class="is-small">
          <b-input v-model="formData.location" size="is-small"></b-input>
        </b-field>

        <b-field>
          <b-checkbox v-model="formData.featured" :true-value="1" :false-value="0" type="is-warning" >Featured</b-checkbox>
        </b-field>

        <b-field>
          <b-checkbox v-model="formData.patreon" :true-value="1" :false-value="0" type="is-danger" >Patreon</b-checkbox>
        </b-field>
      </b-field>

      <b-button type="is-info" native-type="submit" size="is-small" icon-left="search">Search</b-button>
    </form>

  </div>
</template>

<script>

export default {
  name: 'search-form',
  data() {
    return {
      errors: [],
      formData: {
        ...this.$route.query,
      },
      loadingForm: false,
      sent: false,
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const params = {
        ...this.formData,
        page: 1,
      };

      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/users?${queryString}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
